// Overview.js
import React from 'react';
import './Overview.css';  // Link to the CSS file

const Overview = () => {
    return (
        <div className="overview-container">
            <div className="overview-header">
                <h1 className="overview-title">Overview</h1>
                <p className="overview-subtitle">
                    Excavate Research is an emerging leader in the Market Research And Business
                    Intelligence Industry.<br></br><br></br>
                    A group of experienced and like minded market research professionals from a few
                    leading market research organizations came together in early 2010 to pursue their
                    collective passion to understand the mind of the one person behind every business in
                    the world, THE CONSUMER. That is when Excavate Research came into existence
                    and is today considered one of the most promising and innovative market research
                    companies in India.<br></br><br></br>
                    Within a short span of time, we have brought together the most innovative minds
                    backed by world class infrastructure to build a diverse and dynamic team ready to
                    tackle any business problem faced by our clients from several different industries.
                    Our focus on innovation has brought tremendous growth along our way over the
                    past 3 years, we stand at 115+ employees today having worked with the biggest
                    brands in the world.<br></br><br></br>
                    Keeping pace with the always evolving Consumer, we have been continuously
                    evolving and adapting ourselves with the global business trends. Our core services
                    include:<br></br><br></br>
                    Survey Programming<br></br>
                    Data Collection<br></br>
                    Open End Coding<br></br>
                    Data Tabulation<br></br>
                    Data Analysis<br></br>
                    Online/Offline Dashboards Development<br></br><br></br>
                    Monitoring Financial KPI's through live dashboards updated in real time with each
                    single entry in financial models with 100% secured data services<br></br>
                    Competitor landscaping<br></br>
                    Business optimization and streamlining through financial analysis<br></br>
                    Building investors pitches for raising funds through VC/PE<br></br>
                    Investment trend and Future Outlook in the sector<br></br>
                    Company's Financial and operational performance<br></br>
                    Company's Relative Market Position<br></br>
                    Building Business plans & financial modelling<br></br><br></br>
                    Market Sizing and opportunity assessment<br></br><br></br>
                    Excavate launched its Tablet Surveys, Automated PPT and Compliance management
                    tools. We are offering services for customized development based on user
                    requirement. Also we offer consulting for Compliance management.
                </p>
            </div>

            <div className="overview-content">
                <div className="overview-section">
                    <h2>Details</h2>
                </div>

                <div className="Detail-section">
                    <h6>Industry</h6>
                    <p className="Detail-subtitle">
                        Market Research
                    </p>
                </div><br></br>
                <div className="Detail-section">
                    <h6>Company Site</h6>
                    <p className="Detail-subtitle">
                        51-200 employees
                        106 associated members
                    </p>
                </div><br></br>
                <div className="Detail-section">
                    <h6>Headquarters</h6>
                    <p className="Detail-subtitle">
                        Delhi, Delhi
                    </p>
                </div><br></br>
                <div className="Detail-section">
                    <h6>Type</h6>
                    <p className="Detail-subtitle">
                        Privately Held
                    </p>
                </div><br></br>
                <div className="Detail-section">
                    <h6>Founded</h6>
                    <p className="Detail-subtitle">
                        2010
                    </p>
                </div><br></br>
                <div className="Detail-section">
                    <h6>Speciality</h6>
                    <p className="Detail-subtitle">
                        Survey Programming, Data Processing, Data Cleaning, Data Analysis, Data Coding, Data Reports, Dashboards, Dashboards using BI softwares
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Overview;
