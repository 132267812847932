import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useHistory hook
import './Team.css'; // Import CSS for Team component
import MemberSlideshow from './MemberSlideshow';
import profile from '../assets/profile/2017/01/Manish_s-147x118.png'
import profile2 from '../assets/profile/2017/07/Virender_s-177x142.jpg';
import profile3 from '../assets/profile/2017/01/Amit_s-147x118.jpg';
import MoreTeamMembers from './MoreTeamMembers'; // Import MoreTeamMembers
import { Button, Container, Row, Col, Card, CardTitle,CardText, CardImg, CardHeader } from 'reactstrap';
import Home from './Home';

const Team = () => {
  const navigate = useNavigate(); // Get history object
  const [showMoreTeamMembers, setShowMoreTeamMembers] = useState(false); // State to toggle MoreTeamMembers

  const toggleMoreTeamMembers = () => {
    setShowMoreTeamMembers(!showMoreTeamMembers); // Toggle the state
  };

  return (
    <section id="team">
      {/* <Home /> */}
      <Container style={{
      marginTop:"2.5%"}}>
        <div
            color="success"
            outline 
         className="text-center">
        <h3>
        Meet Our Team
    </h3>
        <MemberSlideshow />
        {/* <div className="team-members">
          <div className="team-member">
          <div className="image-container">
            <img src={profile} alt="John Doe" />
            </div>
            <div>
              <p>Manish Khyaliya</p>
              <p>Director</p>
            </div>
          </div>
          <div className="team-member">
          <div className="image-container">
            <img src={profile2} alt="Jane Smith" />
            </div>
            <div>
              <p>Virender Singh</p>
              <p>HR</p>
            </div>
          </div>
          <div className="team-member">
          <div className="image-container"> 
            <img src={profile3} alt="Jane Smith" />
            </div>
            <div>
              <p>Amit Ahlawat</p>
              <p>Director Operations</p>
            </div>
          </div>
        </div> */}
        {/* Toggle MoreTeamMembers */}
        {/* <button onClick={toggleMoreTeamMembers}>
          {showMoreTeamMembers ? "Hide more" : "Show more"}
        </button> 
        {showMoreTeamMembers && <MoreTeamMembers />} */}
        </div>
    </Container>
    </section>
  );
}

export default Team;
