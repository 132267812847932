import React from 'react';
import logo from '../assets/img/logo-small.png';
import './Header.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const getNavLinkClass = (path) => {
    return location.pathname.startsWith(path) ? 'nav-link active' : 'nav-link';
  };

  const handleBackClick = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const showBackButton = () => {
    const paths = [
      "/services/data-analysis",
      "/services/charting",
      "/services/dashboards",
      "/services/scripting",
      "/services/consulting",
      "/services/artificial-intelligence",
    ];
    return paths.includes(location.pathname);
  };

  return (
    <header>
      <Navbar expand="lg" className="navbar">
        <Navbar.Brand className="navbar-brand">
          <img src={logo} alt="Company Logo" className="center-logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" style={{ alignItems: 'baseline', marginLeft: '15%' }}>
            <Nav.Link as={Link} to="/" className={getNavLinkClass('/')}>Home</Nav.Link>
            <Nav.Link as={Link} to="/services" className={getNavLinkClass('/services')}>Services</Nav.Link>
            <Nav.Link as={Link} to="/contact" className={getNavLinkClass('/contact')}>Contact Us</Nav.Link>
            <Nav.Link as={Link} to="/career" className={getNavLinkClass('/career')}>Career</Nav.Link>
            <NavDropdown title="About" id="about-dropdown" className={getNavLinkClass('/about')}>
              <NavDropdown.Item as={Link} to="/about/overview">Overview</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/about/fun-of-era">Fun of ERA</NavDropdown.Item>
            </NavDropdown>
            {location.pathname !== '/' && showBackButton() && (
              <Nav.Link href="#" onClick={handleBackClick} className="nav-link back-link">Back</Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

export default Header;
