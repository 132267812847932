import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Mission from './components/Mission';
import Services from './components/Services';
import Team from './components/Team';
import Contact from './components/Contact';
import ContactUsDetailed from './components/ContactUsDetailed';
import ServiceDetail from './components/ServiceDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import ClientsPage from './components/ClientsPage';
import Overview from './components/Overview';

const MainContent = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleReadMoreClick = () => {
    navigate('/contact-us-detailed');
  };

  return (
    <div style={{overflowX:"hidden"}}>
      <Header />
      <main>
        {(location.pathname === '/') && (
          <Home>
            <Mission />
            <Services />
            <Team />
            <Contact onReadMoreClick={handleReadMoreClick} />
          </Home>
        )}
        <Routes>
          <Route path="/services" element={<Services />} />
          <Route path="/" element={<>
            <Mission />
            {/* <ClientsPage /> */}
          </>} />
          <Route path="/contact" element={<Contact onReadMoreClick={handleReadMoreClick} />} />
          <Route path="/services/:serviceName" element={<ServiceDetail />} />
          <Route path="/career" element={<ContactUsDetailed />} />
          <Route path="/about/overview" element={<Overview />} />
          <Route path="/about/fun-of-era" element={<Team />} />
        </Routes>
      </main>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <MainContent />
      <Footer />
    </Router>
  );
};

export default App;
