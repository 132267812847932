// Mission.js
import React from 'react';
import './Mission.css';
import { Button, Container, Row, Col, Card, CardTitle,CardHeader } from 'reactstrap';

const Mission = () => {
  return (
    <section id="mission">
      <Container>
     <div
         color="success"
         outline
         className="text-center">
     <h3>
      Our Mission
    </h3>
      <p>At Excavate Research & Analysis Pvt. Ltd., our mission is to empower organizations with actionable insights derived from data. We are committed to delivering comprehensive data analysis solutions that drive informed decision-making and foster business success. Through innovative approaches and advanced technologies, we strive to unlock the full potential of data, enabling our clients to navigate complexities, seize opportunities, and stay ahead in today's dynamic business landscape. With integrity, expertise, and a relentless pursuit of excellence, we aim to be the trusted partner of choice for organizations seeking to harness the power of data to achieve their goals and create lasting impact.</p>
    
      </div>
      </Container>
    </section>

  );
}

export default Mission;
