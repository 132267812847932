// Home.js
import React from 'react';
import './Home.css';
import Slideshow from './Slideshow';
import { Container, Card, CardTitle} from 'reactstrap';
const Home = () => {
  return (
    <section id="home" className="home-section">
       <Slideshow />
       <CardTitle tag="h2" style={{marginTop:'4vh', marginBottom:'3vh'}}>
      Welcome to Excavate Research & Analysis Pvt. Ltd.
      </CardTitle>
    </section>
  );
}

export default Home;
